<template>
    <div class="LoadingBlurCom">
        <van-overlay :show="active" class="overlay-content">
            <van-loading size="36px" vertical>{{ t('common.noti.loading') }}</van-loading>
        </van-overlay>
    </div>
</template>
<script setup>
import { useStore } from "vuex";
import { computed, ref } from "vue";
import i18n from "@/i18n";


// MARK - Props
// ************************************************************************
const { t } = i18n.global
const store = useStore()
const active = computed(() => {
    return store.getters.isLoading
})

// MARK - LIFE CYCLE
// ************************************************************************

// MARK - Methods
// ************************************************************************


</script>
<style>
.overlay-content {
    justify-content: center;
    display: flex;
    align-items: center;
}
</style>
