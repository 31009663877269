import { setCookie, getCookie } from "../cookies/cookieMgr"
import { userLoginService, userInfoService } from "@/services/userService"
import { showFailToast } from 'vant'

const user = {
    state: {
        token   : "",   // token
        userInfo: {},   // 用户信息
    },

    getters: {
        /**
         * Get the token from the cookie and set it in the state object.
         *
         * @param {Object} state - The state object containing the token.
         * @return {string} The token value.
         */
        getToken: (state) => {
            var token = getCookie("token")
            if (token == undefined) {
                token = ""
            }
            state.token = token
            return state.token
        },
        /**
         * Retrieves the user information from the state.
         *
         * @param {object} state - The state object.
         * @return {object} The user information from the state.
         */
        getUserInfo: (state) => {
            return state.userInfo
        }
    },

    mutations: {
        /**
         * Set the token in the state.
         *
         * @param {type} state - the state object
         * @param {type} token - the token to set
         * @return {type} undefined
         */
        SET_TOKEN(state, payload) {
            setCookie("token", payload.token)
            state.token = payload.token
        },
        SET_USER_INFO(state, payload) {
            state.userInfo = payload
        }
    },

    actions: {
        /**
         * Logs in a user with the provided user information.
         *
         * @param {Object} commit - The Vuex commit function.
         * @param {Object} userInfo - The user information to be used for login.
         * @return {Promise} A promise that resolves when the login is successful.
         */
        Login({ commit }, userInfo) {
            return new Promise((resolve, reject) => {
                userLoginService(userInfo).then(res => {
                        const token = res.data.token
                        console.log(token)
                        commit('SET_TOKEN', { 'token': token })
                        resolve()
                    }).catch(error => {
                        showFailToast('登录失败!')
                        // reject(error)
                    })
            })
        },
        /**
         * Retrieves user information.
        *
        * @param {Object} commit - The commit object.
        * @return {Promise} A promise that resolves with the user information.
        */
        FetchUserInfo({ commit, getters }) {
            let token = getters.getToken
            let data = {
                token: token
            }
            userInfoService(data).then((res) => {
                commit('SET_USER_INFO', res.data)
            }).catch((error) => {
                console.log(error)
            })
        },
        /**
         * Logout function that clears the token.
         *
         * @param {object} commit - function to commit changes
         * @return {void}
         */
        Logout({ commit }) {
            commit('SET_TOKEN', {'token': ''})
        },
    },
}

export default user
