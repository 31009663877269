<template>
    <div class="NavbarCom">
        <van-nav-bar
            :title="title"
            :left-text="isPageZero || disableBack ? '' : t('common.button.back')"
            :left-disabled="isPageZero || disableBack"
            :left-arrow="isPageZero || disableBack ? false : true"
            @click-left="onClickLeft"
        >
        </van-nav-bar>
    </div>
</template>
<script setup>
import i18n from '@/i18n';
import router from '@/router'
import { computed } from 'vue';
import { useStore } from 'vuex';


// MARK - Props
// ************************************************************************
const store = useStore()
const { t } = i18n.global
// get the current page title
const title = computed(() => {
    return store.getters.getPageTitle
})
// get the current page depth( is root or not )
const isPageZero = computed(() => {
    // colog.error("isPageZero -> " + store.getters)
    // colog.error("111 " + store.getters.getPageDepth)
    return store.getters.isPageDepthZero
})
const disableBack = computed(() => {
    return store.getters.getDisableBack
})


// MARK - Methods
// ************************************************************************
const onClickLeft = () => {
    console.log(router)
    router.back()
}


</script>
<style>
</style>
