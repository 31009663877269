import { useStore } from 'vuex'
import { createRouter, createWebHistory } from 'vue-router'

const routes = [
    {
        path: "/",
        name: "DashboardView",
        meta: { requiresAuth: true },
        component: () => import("../views/dashboard/DashboardView.vue"),
    },
    {
        path: "/function",
        name: "FunctionListView",
        meta: { requiresAuth: true },
        component: () => import("../views/function/FunctionListView.vue"),
    },
    {
        path: "/function/product-category",
        name: "FnProductCategoryView",
        meta: { requiresAuth: true },
        component: () => import("../views/function/FnProductCategoryView.vue"),
    },
    {
        path: "/login",
        name: "LoginView",
        component: () => import("../views/auth/LoginView.vue"),
    },
    {
        path: "/:pathMatch(.*)*",
        name: "NotFoundView",
        component: () => import("../views/404/NotFoundView.vue"),
    }
]


const router = createRouter({
    history: createWebHistory(),
    routes: routes
})


// MARK - 路由守卫
let lastPosition = 0
// ************************************************************************
router.beforeEach((to, from, next) => {
    const store = useStore()
    // 解决刷新后页面不会回到顶部的问题
    document.body.scrollTop            = 0;  // firefox
    document.documentElement.scrollTop = 0;  // safari
    window.pageYOffset                 = 0;  // 调用 next()，一定要调用 next 方法，否则钩子就不会被销毁

    // 获取用户登录状态
    const token = store.getters.getToken
    console.log(token)
    console.log(to.meta.requiresAuth)
    console.log(token.length < 1)
    if (to.meta.requiresAuth && token.length < 1) {
        next({ name: 'LoginView' })
        return
    } else {
        // 获取用户信息
        if (token.length > 0) {
            store.dispatch('FetchUserInfo')
        }
        next()
    }
})


router.afterEach((to, from) => {
    const store = useStore()
    const curPosition = window.history.state.position
    if (curPosition > lastPosition) {
        store.commit('addPageDepth')
    }
    if (curPosition < lastPosition) {
        store.commit('page/reducePageDepth')
    }
    // colog.debug("history position => " + window.history.state.position)
})

export default router
