
// backend API Version
let API_VERSION = 'v1'
let API_LOADING_DURATION = 200   // loading duration
let BASE_URL_PROD = "https://goodie.megaplus.co.nz"
let BASE_URL_DEV = "https://test-api.megaplus.co.nz"
let BASE_PATH = "/api/v1"

module.exports = {
    API_PROD: BASE_URL_PROD + BASE_PATH,
    API_DEV : BASE_URL_DEV + BASE_PATH,
    API_LOADING_DURATION: API_LOADING_DURATION,
    BACKEND_SECRET_KEY: "dc3dfeb3e55144ba1fdcce7dd6fcdbf7",
    UPLOAD_URL: BASE_URL_PROD + "/static/upload/",
    DEFAULT_IMAGE_URL: "https://images.megabit.co.nz/images/megaplus/logo/placeholder_v1.png",
    SENTRY_URL: "https://77ca9f4052e04e70bb6e50e21838123f@sentry.megabit.co.nz/3"
}
