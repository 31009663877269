import { get, post } from "@/utils/request.js"
import { userApi } from "./base/urlConfig.js"

/**
 * @func 获取用户销售情况
 *
 * @param {params} parameter
 */
export function userFetchSalesService(params) {
    var url = userApi.FetchUserSales
    return get(url, params)
}

/**
 * @func 获取所有用户
 */
export function userFetchAllService() {
    var url = userApi.FetchAllUser
    return get(url)
}

/**
 * @func 获取所有用户 - pagination
 *
 * @param {params} parameter
 */
export function userFetchAllPaginationService(params) {
    var url = userApi.FetchAllUserPagination
    return get(url, params)
}

/**
 * @func 获取用户权限列表
 */
export function userRoleListFetchAllService() {
    var url = userApi.FetchAllUserRoleList
    return get(url)
}

/**
 * @func 获取一个用户
 *
 * @param {Integer} userID
 */
export function fetchUserByUserIDService(userID) {
    var url = userApi.FetchUserByID + userID + "/"
    return get(url)
}

/**
 * @func 创建用户
 *
 * @param {params} parameter: 用户数据
 */
export function userCreateService(params) {
    var url = userApi.CreateUser
    return post(url, params, true)
}

/**
 * @func 更新用户 by id
 *
 * @param {Integer} user_id: user ID
 * @param {params} parameter: 用户数据
 */
export function userUpdateService(user_id, params) {
    var url = userApi.UpdateUser + user_id + "/"
    return post(url, params, true)
}

/**
 * @func 删除用户 by id
 *
 * @param {Integer} user_id: user ID
 */
export function userDeleteByUserIDService(user_id) {
    var url = userApi.DeleteUser + user_id + "/"
    return get(url)
}

/**
 * @func 用户登录
 *
 * @param {params} parameter
 */
export function userLoginService(params) {
    var url = userApi.Login
    return post(url, params, true)
}

/**
 * @func 用户信息查询
 *
 * @param {params} parameter
 */
export function userInfoService(params) {
    var url = userApi.FetchInfo
    return get(url, params)
}

/**
 * @func 用户Nav查询
 *
 * @param {String} token
 */
export function userNavService(token) {
    var url = userApi.FetchNav
    return get(url, {
        token: token
    })
}
